import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  titleTemplate,
  description,
  image,
  imageWidth,
  imageHeight,
  pathname,
  article,
  lang,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultTitleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        titleTemplate: titleTemplate || defaultTitleTemplate,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        url: `${siteUrl}${pathname || ""}`,
      }
      return (
        <>
          <Helmet
            htmlAttributes={{ lang }}
            title={seo.title}
            titleTemplate={seo.titleTemplate}
          >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={`${seo.url}/`} />}
            {article ? (
              <meta property="og:type" content="article" />
            ) : (
              <meta property="og:type" content="website" />
            )}
            <meta property="fb:app_id" content="729566207468737" />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            {seo.imageWidth && (
              <meta property="og:image:width" content={seo.imageWidth} />
            )}
            {seo.imageHeight && (
              <meta property="og:image:height" content={seo.imageHeight} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <link rel="dns-prefetch" href="//fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  lang: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  titleTemplate: "%s | Sicarpo",
  description: null,
  image: null,
  pathname: null,
  article: false,
  lang: `pt`,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
